import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";

export default {
  async setarModoAluno(item) {

    let dados = {
      IdUsuario: item.Id,
      UsuarioNome: item.Nome
    }

    try {
      const url = `${endpoints.salaDeAula}/ativar-modo-aluno`;
      await api.post(url, dados)

      window.localStorage.setItem('idModoAluno', item.Id)
      window.localStorage.setItem('nomeModoAluno', item.Nome)

    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }

  },
  async sairModoAluno() {
    window.localStorage.removeItem('idModoAluno')
    window.localStorage.removeItem('nomeModoAluno')

  },
  checkModoAluno() {
    return (window.localStorage.getItem("idModoAluno")) ? true : false

  },
  getIdModoAluno() {
    return parseInt(window.localStorage.getItem("idModoAluno"))

  },
  getNomeModoAluno() {
    return window.localStorage.getItem("nomeModoAluno")

  },
}