import helperService from './helper.service'
import APIError from '../models/api/api.error'
import authService from "../Services/Auth.service";

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default {
  executeAPI: async (url, method, body, headers) => {

    url = BASE_URL + API_VERSION + url;

    function executeFecth(url, method, body, headers, retries = 2) {

      return fetch(url, {
        method: method,
        headers: headers,
        timeout: 60,
        body: body ? JSON.stringify(body) : undefined,
      }).then(response => {
        return helperService.getHttpErrror(response).then(responseError => {
          if (responseError) {
            return responseError;
          } else {
            return response.json();
          }
        });
      }).then(responseJson => {
        return responseJson;
      }).catch(async error => {

        if (error instanceof TypeError && error.message === "Failed to fetch") {
          // Checa se o erro é de conexão e reduz as tentativas
          if (method === 'GET' && retries > 0) {
            console.warn(`Erro de conexão. Tentando novamente... (${3 - retries} tentativa)`);
            await delay(300); // Espera de 0,3 segundos
            return await executeFecth(url, method, body, headers, retries - 1);
          }
        }

        // Se as tentativas se esgotarem ou for outro tipo de erro
        if (error instanceof TypeError && error.message === "Failed to fetch") {
          console.error("Erro de conexão: Não foi possível alcançar o servidor.");

          if (retries === 0) {
            try {
              await delay(300);
              await authService.logUsuario(url, method, body, headers);
            } catch (e) {
              console.log('erro no envio do log', e)
            }
          }

          return {
            Message: `Erro de conexão: Não foi possível alcançar o servidor.`,
            Type: 'error',
            Error: 'Erro de conexão',
          };
        }

        return helperService.getHttpErrror(error).then(responseError => {
          return responseError;
        });
      });
    }

    return await executeFecth(url, method, body, headers)

  },
  executeUploadAPI: async (url, method, formData, headers) => {
    url = BASE_URL + API_VERSION + url
    return fetch(url, {
      method: method,
      headers: headers,
      body: formData,
      timeut: 320,
    }).then(response => {
      return helperService.getHttpErrror(response).then(responseError => {
        if (responseError) {
          return responseError;
        } else {
          return response.json()
        }
      })
    }).then(responseJson => {
      return responseJson
    }).catch(error => {
      console.log(error);
      return helperService.getHttpErrror(error).then(responseError => {
        return responseError;
      })
    })
  },
  executeExternal: async (url, method, headers) => {
    return fetch(url, {
      method: method,
      headers: headers,
      timeut: 60,
    }).then(response => {
      return helperService.getHttpErrror(response).then(responseError => {
        if (responseError) {
          return responseError;
        } else {
          return response.json()
        }
      })
    }).then(responseJson => {
      return responseJson
    }).catch(error => {
      return helperService.getHttpErrror(error).then(responseError => {
        return responseError;
      })
    })
  },
  executeDownloadBlob: async (url, method, headers) => {
    url = BASE_URL + API_VERSION + url

    return fetch(url, {
      method: method,
      headers: headers,
      timeut: 120,
    })
    .then(response => response.arrayBuffer())  // Transformar em ArrayBuffer
    .then(buffer => {
      return buffer;
    })
    .catch(error => {
      console.error('Erro ao carregar o PDF:', error);
    });

  },

}
