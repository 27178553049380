const APIBaseEndpoints = {
  AuthController: '/auth',
  DataController: '',
}

const APIEndpoints = {

  // endpoints login
  authLogin: APIBaseEndpoints.AuthController + '/login',
  authValidate: APIBaseEndpoints.AuthController + '/validate-login',
  authLogout: APIBaseEndpoints.AuthController + '/logout',
  authRefreshToken: APIBaseEndpoints.AuthController + '/refresh',
  authForgotPassword: APIBaseEndpoints.AuthController + '/forgot-password',
  authChangePassword: APIBaseEndpoints.AuthController + '/change-password',
  logUsuario: APIBaseEndpoints.AuthController + '/log-usuario',

  ucaERP: APIBaseEndpoints.DataController +'/uca-erp',
  log: APIBaseEndpoints.DataController +'/log',

  // endpoints of LMS models
  perfilUsuario: APIBaseEndpoints.DataController +'/perfil-usuario',
  perfilPermissoes: APIBaseEndpoints.DataController +'/perfil-permissoes',
  perfil: APIBaseEndpoints.DataController +'/perfil',
  permissoes: APIBaseEndpoints.DataController +'/permissoes',
  usuario: APIBaseEndpoints.DataController +'/usuario',

  disciplina: APIBaseEndpoints.DataController +'/disciplina',
  curso: APIBaseEndpoints.DataController +'/curso',
  modulo: APIBaseEndpoints.DataController +'/modulo',
  tipoModulo: APIBaseEndpoints.DataController +'/tipo-modulo',
  aula: APIBaseEndpoints.DataController +'/aula',
  mensagem: APIBaseEndpoints.DataController +'/mensagem',
  galeria: APIBaseEndpoints.DataController +'/galeria',
  turma: APIBaseEndpoints.DataController +'/turma',
  turmaAluno: APIBaseEndpoints.DataController +'/turma-aluno',
  turmaDisciplina: APIBaseEndpoints.DataController +'/turma-disciplina',
  instrutorDisciplina: APIBaseEndpoints.DataController +'/instrutor-disciplina',
  bancoPergunta: APIBaseEndpoints.DataController +'/banco-pergunta',

  salaDeAula: APIBaseEndpoints.DataController +'/sala-de-aula',
  acompanhamento: APIBaseEndpoints.DataController +'/acompanhamento',
  notas:  APIBaseEndpoints.DataController +'/notas',
  alunoNotas:  APIBaseEndpoints.DataController +'/aluno-notas',
  relatorios: APIBaseEndpoints.DataController +'/relatorios',

  atividade: APIBaseEndpoints.DataController +'/atividade',
  tipoAtividade: APIBaseEndpoints.DataController +'/tipo-atividade',
  pergunta: APIBaseEndpoints.DataController +'/pergunta',
  tipoPergunta: APIBaseEndpoints.DataController +'/tipo-pergunta',
  alternativa: APIBaseEndpoints.DataController +'/alternativa',
}

export default APIEndpoints;
