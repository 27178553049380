import ServiceResponseCode from '../models/service.response.code'
import APIError from '../models/api/api.error'
import moment from "moment";

export default {
  getTheme() {

    let theme = process.env?.VUE_APP_MODE ?? null;

    if (['development', 'homologation'].includes(process.env.NODE_ENV))
      theme = window.localStorage.getItem('theme') ?? null

    if (!theme)
      theme = (window.location.host.includes('esn')) ? 'esn' : 'uca'

    return theme;
  },
  getUCAWhatsappLink() {
    return 'https://api.whatsapp.com/send?phone=5514981410233'
  },
  getESNWhatsappLink() {
    return 'https://api.whatsapp.com/send?phone=5514982272827'
  },
  getMessageError(result) {
    return `${result.Message}|${result.Type ?? 'error'}`;

  },
  messageExceptionHandler(exception, subErrorMessage = null) {
    const message = exception.message.split('|');
    if (message[1])
      switch (message[1]) {
        case 'error':
          swalAlert.fire('Erro', subErrorMessage ?? message[0], 'error');
          break;
        case 'warning':
          swalAlert.fire('Atenção', message[0], 'warning');
          break;
      }
    else
      swalAlert.fire('', exception.message, '');

  },
  getServiceResponse(response) {

    if (!response) {
      return {
        Code: ServiceResponseCode.ResponseNotFound,
        Message: 'Not Found',
        Success: false,
        Type: response.Type
      }
    }

    if (response.Success) {
      return {
        Code: response.Code,
        Message: response.Message,
        Success: true,
        Data: response.Data,
        Type: 'success'
      }
    } else {
      return {
        Code: response.Code,
        Message: response.Message,
        Success: false,
        Data: response.Data,
        Type: response.Type
      }
    }

  },
  async getHttpErrror(response) {

    if (response.status == 200 || response.status == 201 || response.status == 202 || response.status == 206)
      return null

    if (response.status == 406) {
      return {
        Message: `Não autorizado. Sessão expirada faça o Login novamente`,
        Type: 'error',
        Error: 'Sessão Expirada'
      }
    }

    const url = new URL(response.url);
    const pathOnly = url.pathname.replace('/api/v1/', '');
    const text = await response.text()

    return {
      Message: `API Erro: ${response.status}: ${response.statusText}, ${response.type.toUpperCase()}, '${pathOnly}'`,
      Type: 'error',
      Error: JSON.parse(text)?.errors ?? ''
    }
  },
  getHttpErrorCode(error) {
    if (!error || !(error instanceof APIError)) {
      return ServiceResponseCode.CatchAPICall
    } else {
      return error?.data?.Code ?? ServiceResponseCode.CatchAPICall
    }
  },
  getURLParams(params = []) {
    if (!params) {
      return ''
    }

    params = params.filter(p => p.Name && p.Value)

    if (params.length == 0) {
      return ''
    }

    let queryString = '?'

    params.forEach(param => {
      queryString += (queryString == '?' ? '' : '&') + param.Name + '=' + encodeURI(param.Value)
    })

    return queryString
  },
  ucWords(s) {
    let str = s.toLowerCase()
    let re = /(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g
    return str.replace(re, s => s.toUpperCase())
  },
  getTokenParam(token, param) {

    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    const data = JSON.parse(jsonPayload)

    return data[param] ?? null

  },
  formatMoney(value) {
    const formCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      roundingIncrement: 5
    })
    return formCurrency.format(value)
  },
  formatDecimal(value) {
    const formCurrency = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2
    });
    return formCurrency.format(value)
  },
  formatPercentage(value) {
    return value.toString().replaceAll('.', ',') + '%'
  },
  formatDateTime(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD/MM/YYYY HH:mm:ss");
  },
  formatDateHourMin(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD/MM/YYYY HH:mm");
  },
  formatDate(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format("DD/MM/YYYY");
  },
  formatDateTimeUs(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format("YYYY-MM-DD HH:mm:ss");
  },
  formatDatePickerToUs(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "MM/DD/YYYY").format("YYYY-MM-DD");
  },
  formatDatePicker(dateTime) {
    if (!dateTime) return null
    return moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format("MM/DD/YYYY");
  },
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  },
  isResponsive() {
    return window.innerWidth <= 992
  },
  scrollToAdjusted(id, offset = 0) {
    const element = document.getElementById(id);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - offset,
      behavior: "smooth"
    });
  },
  ukWords(str) {
    let val = ''
    if (str != null) {
      val = str.match(/[a-zA-Z][a-z]+/g)
      val = val.join(' ')
      val = val.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (letter) {
        return letter.toUpperCase();
      });
    }
    return val
  },
  stringToUrl(str) {
    return str.replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  getEmbedLinkYoutube(link) {
    let linkSplit = link.split('v=')
    return `https://www.youtube.com/embed/${linkSplit[1]}`;
  },
  formatIdDisciplina(id) {
    return ('000000' + id).slice(-6);
  },
  addDaysDate(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  },
  removeDaysDate(date, days) {
    date.setDate(date.getDate() - days);
    return date;
  },
  readFile(file) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve({
          url: event.target.result,
          file: file,
        });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });

  },
  formatMinutes(minutes) {

    if (minutes) {

      const hours = Math.floor(minutes / 60);
      const minutesRemaining = minutes % 60;

      let timeByExtension = '';

      if (hours > 0) {
        timeByExtension += `${hours} hora${hours !== 1 ? 's' : ''}`;
      }

      if (minutesRemaining > 0) {
        if (hours > 0) {
          timeByExtension += ' e ';
        }
        timeByExtension += `${minutesRemaining} minuto${minutesRemaining !== 1 ? 's' : ''}`;
      }

      return timeByExtension;

    }

    return 'Tempo livre'
  },
  validarHorario(horario) {
    // Verifica se o formato do horário é válido
    if (/^([01]\d|2[0-3]):([0-5]\d)$/.test(horario)) {
      // Divide as partes do horário
      var partes = horario.split(":");
      var horas = parseInt(partes[0]);
      var minutos = parseInt(partes[1]);

      // Verifica se as horas e os minutos estão dentro dos limites
      if (horas >= 0 && horas <= 23 && minutos >= 0 && minutos <= 59) {
        return true; // Horário válido
      }
    }

    return false; // Horário inválido
  },
  getGuid() {
    return 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16).toUpperCase();
    })
  }

}
