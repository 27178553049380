import authService from "../Services/Auth.service";
import Helper from "../helpers/helper.service";

const MenuLogin = {
  methods: {
    async logout() {
      try {

        swalLoading.fire('', 'Realizando logout, aguarde...')

        await authService.Logout();

        swalLoading.close();

        await this.$router.push('/login')

      } catch (e) {
        Helper.messageExceptionHandler(e)
      }
    },
  },
}

export default MenuLogin